import React from "react";
import styled from "styled-components";

import airspace from "./images/airspace.jpeg";
import b2 from "./images/b2.jpeg";
// import boat from "./images/boat.jpeg";
import bts from "./images/bts.jpeg";
import bts2 from "./images/bts2.jpeg";
// import bu from "./images/bu.jpeg";
import con3 from "./images/con3.jpeg";
import cons from "./images/cons.jpeg";
import cons4 from "./images/cons4.jpeg";
import gas from "./images/gas.jpeg";
import grinder from "./images/grinder.jpeg";
import hiace from "./images/hiace.jpeg";
import loader from "./images/loader.jpeg";
import machine from "./images/machine.jpeg";
import marin from "./images/marin.jpeg";
import mboat from "./images/mboat.jpeg";
// import mining from "./images/mining.jpeg";
import mtu from "./images/mtu.jpeg";
import ndrill from "./images/ndrill.jpeg";
import oilpipe from "./images/oilpipe.jpeg";
// import pipe from "./images/pipe.jpeg";
import port from "./images/port.jpeg";
import pump from "./images/pump.jpeg";
import sign from "./images/sign.jpeg";
import site from "./images/site.jpeg";
import smallboat from "./images/smallboat.jpeg";
import tower from "./images/tower.jpeg";
// import tz from "./images/tz.jpeg";
import ujenzi from "./images/ujenzi.jpeg";
// import well from "./images/well.jpeg";
import work from "./images/work.jpeg";

const App = () => {
  const isMobile = window.innerWidth < 600;

  return (
    <Container>
      <Content>
        <Grid>
          <Card
            style={{
              background: `url(${b2}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Construction </p>
          </Card>

          <Card
            style={{
              background: `url(${marin}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Marine Works </p>
          </Card>
          <Card
            style={{
              background: `url(${gas}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Gas Services </p>
          </Card>

          {isMobile ? (
            <MainCard>
              <h1>SASCO ENGINEERING</h1>
              <h3>
                <a href="mailto:info@sascoengineering.com">
                  info@sascoengineering.com
                </a>
              </h3>
              <h3>
                <a href="tel:+255 784 960000">+255 784 960000</a>
              </h3>

              <div style={{ marginTop: 25 }}>
                <h4>CONSTRUCTION</h4>
                <h4>OIL AND GAS SERVICES</h4>
              </div>
              <h4>MARINE ENGINEERING WORKS</h4>
            </MainCard>
          ) : null}

          <Card
            style={{
              background: `url(${mtu}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> SASCO </p>
          </Card>
          <Card
            style={{
              background: `url(${machine}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Machinery </p>
          </Card>

          <Card
            style={{
              background: `url(${pump}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Oil Services </p>
          </Card>

          {!isMobile ? (
            <MainCard>
              <h1>SASCO ENGINEERING</h1>
              <h3>
                <a href="mailto:info@sascoengineering.com">
                  info@sascoengineering.com
                </a>
              </h3>
              <h3>
                <a href="tel:+255 784 960000">+255 784 960000</a>
              </h3>

              <div style={{ marginTop: 25 }}>
                <span>
                  <h4 style={{ marginRight: 25 }}>CONSTRUCTION</h4>
                </span>
                <span>
                  <h4>OIL AND GAS SERVICES</h4>
                </span>
              </div>
              <h4>MARINE ENGINEERING WORKS</h4>
            </MainCard>
          ) : null}

          {/* <Card
            style={{
              background: `url(${tz}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Tanzania </p>
          </Card> */}

          <Card
            style={{
              background: `url(${tower}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p>Machinery</p>
          </Card>

          <Card
            style={{
              background: `url(${loader}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Construction </p>
          </Card>
          <Card
            style={{
              background: `url(${sign}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Machinery </p>
          </Card>

          <Card
            style={{
              background: `url(${bts}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Exploration </p>
          </Card>
          <Card
            style={{
              background: `url(${bts2}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Engineering </p>
          </Card>
          <Card
            style={{
              background: `url(${con3}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p>Heavy Equipment</p>
          </Card>
          <Card
            style={{
              background: `url(${cons4}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Heavy Duty </p>
          </Card>
          <Card
            style={{
              background: `url(${hiace}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Logistics </p>
          </Card>
          <Card
            style={{
              background: `url(${tower}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Telecom </p>
          </Card>

          <Card
            style={{
              background: `url(${mboat}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Exploration </p>
          </Card>
          <Card
            style={{
              background: `url(${mtu}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Engineering </p>
          </Card>
          <Card
            style={{
              background: `url(${ndrill}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p>Heavy Equipment</p>
          </Card>
          <Card
            style={{
              background: `url(${oilpipe}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Heavy Duty </p>
          </Card>
          <Card
            style={{
              background: `url(${port}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Ports </p>
          </Card>
          <Card
            style={{
              background: `url(${smallboat}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Marine Exploration </p>
          </Card>

          <Card
            style={{
              background: `url(${work}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Exploration </p>
          </Card>
          <Card
            style={{
              background: `url(${grinder}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Engineering </p>
          </Card>
          <Card
            style={{
              background: `url(${cons}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p>Heavy Equipment</p>
          </Card>
          <Card
            style={{
              background: `url(${airspace}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Heavy Duty </p>
          </Card>
          <Card
            style={{
              background: `url(${ujenzi}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> Heavy Duty </p>
          </Card>
          <Card
            style={{
              background: `url(${site}) no-repeat center top`,
              backgroundSize: "cover",
            }}
          >
            <p> SASCO </p>
          </Card>
        </Grid>
      </Content>
    </Container>
  );
};

export default App;

const Container = styled.div``;

const Content = styled.div`
  font-family: "Open Sans", sans-serif;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

const Card = styled.div`
  height: 300px;

  background-color: #000;
  padding: 0;
  margin: 0;
  text-align: center;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 3, 3, 0);
    color: rgba(6, 6, 6, 0);
    margin: 0;

    font-size: 16px;
    font-weight: bold;
  }

  p:hover {
    background-color: rgba(3, 3, 3, 0.5);
    color: #fff;
  }

  @media (max-width: 1024px) {
    p {
      background-color: rgba(3, 3, 3, 0.5);
      color: #fff;

      width: 100%;
      height: 20%;
    }
  }
`;

const MainCard = styled.div`
  grid-column: auto / span 3;
  height: 300px;

  text-align: center;

  h1 {
    margin-top: 40px;
    font-size: 40px;
  }

  h3 {
    a {
      color: #000;
      text-decoration: none;
    }
  }

  h4 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  span {
    display: inline-block;
  }

  @media (max-width: 600px) {
    display: block;
    height: 350px;
  }
`;
